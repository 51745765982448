// ATTN we use these constants in data dump
const abTests = {
  // CRO tests
  tyHideAddedProduct: 'ty:hideAddedProduct', // CRO-270
  paymentOrderPreviewDropdown: 'payment:orderPreviewDropdown', // CRO-417
  globalSubscribeBannerUpdate: 'global:subscribeBannerUpdateV2', // CRO-488
  queuePageRedesign: 'queue:pageRedesign', // CRO-476
  scentProfileApplePay: 'scentProfile:applePay', // CRO-445
  popupHidePopups: 'popup:hidePopups', // CRO-475
  popupFirstTimeVisitModalRedesign: 'popup:firstTimeVisitModalRedesign', // CRO-490
  homeWhatYouGet: 'home:whatYouGet', // CRO-393
  heroServiceAndOfferDescriptionDivision: 'home:heroServiceAndOfferDescriptionDivision', // CRO-402
  globalHideCurrencySign: 'global:hideCurrencySign', // CRO-435
  paymentApplePayOnTop: 'payment:appleOnTop', // CRO-446
  paymentBrandedColors: 'payment:brandedColors', // CRO-487
  tyDriftCarImage: 'ty:driftCarImage', // CRO-495
  popupAlreadyTriedThis: 'popup:alreadytriedthis', // CRO-408
  homeQuizSection: 'home:quizSection', // CRO-509
  homeCirclesWithBottles: 'home:circlesWithBottles', // CRO-505
  homeHeroImage: 'home:heroImage', // CRO-388
  mainEstimatedDelivery: 'main:estimatedDelivery', // CRO-477
  // PF tests
  constructorRecommendations: 'constructorRecommendations', // PF-2319
  aiSummaryReview: 'aiSummaryReview', // PF-2847
} as const

export type AbTestName = typeof abTests[keyof typeof abTests]


export default abTests
